<template>
  <div> 
    <div class="row d-flex justify-content-between align-items-end">
      <div class="col-3">
        <label for="supplierList"> Fournisseur </label>
        <input
          v-model="supplier_reference"
          @input="onSupplierChangeSelected(itemCode)"
          list="supplierList"
          class="form-control"
          id="supplierInput"
        />
        <datalist id="supplierList">
          <option
            v-for="(supplier, index) in suppliers"
            :key="index"
            :value="supplier.reference"
          >
            {{ supplier.fullName }} {{ supplier.society }}
          </option>
        </datalist>
      </div>

      <div class="col-2">
        <label for="itemCodeInput"> Code </label>
        <input
          v-model="itemCode"
          @input="onItemCodeChangeSelected(itemCode)"
          list="itemCodeList"
          class="form-control"
          id="itemCodeInput"
        />
        <datalist id="itemCodeList">
          <option
            v-for="(item, index) in items"
            :key="index"
            :value="item.code"
          >
            {{ item.code }}
          </option>
        </datalist>
      </div>

      <div class="col-3">
        <label for="itemInput">Produit</label>
        <input
          v-model="item_reference"
          @input="onItemChangeSelected(item_reference)"
          list="itemList"
          class="form-control"
          id="itemInput"
        />
        <datalist id="itemList">
          <option
            v-for="(item, index) in items"
            :key="index"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </datalist>
      </div>

      <div class="col-2">
        <label for=""> Du </label>
        <input v-model="filter.dateStart" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> Au </label>
        <input v-model="filter.dateEnd" type="date" class="form-control" />
      </div>
    </div>
    <br />
    <div class="row justify-content-end align-items-end">
      <div class="col-3">
        <label for=""> Fournisseur </label>
        <input
          type="text"
          :value="selectedSupplier.society"
          class="form-control"
          :disabled="true"
        />
      </div>

      <div class="col-4">
        <label for=""> Produit </label>
        <input
          type="text"
          :value="selectedItem.fullName"
          class="form-control"
          :disabled="true"
        />
      </div>
      <div class="col"></div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="empty()" class="btn btn0 btn-secondary">Vider</button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="search(filter)" class="btn btn0 btn-secondary">
          Rechercher
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="refresh()" class="btn btn0 btn-secondary">
          Actualiser
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Designation</th>
          <th scope="col">Fournisseur</th>
          <th scope="col">Date de reception</th>
          <th scope="col">Prix d'achat</th>
          <th scope="col">Quantité Achtee</th>
          <th scope="col">Quantité en Stock</th>

          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(purchaseItem, index) in all" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            <span v-if="purchaseItem.item">
              {{ purchaseItem.item.fullName }}
            </span>
          </td>
          <td>
            <span v-if="purchaseItem.purchase">
              <span v-if="purchaseItem.purchase.supplier">
                {{ purchaseItem.purchase.supplier.fullName }}
                {{ purchaseItem.purchase.supplier.society }}
              </span>
            </span>
          </td>

          <td>
            <span v-if="purchaseItem.purchase">
              {{ purchaseItem.purchase.date | date }}
            </span>
          </td>
          <td class="">
            <span v-if="purchaseItem.priceTTC != null">
              {{ purchaseItem.priceTTC }}
            </span>
          </td>
          <td class="">
            <span v-if="purchaseItem.quantity != null">
              {{ purchaseItem.quantity }}
            </span>
          </td>

          <td>
            <span v-if="purchaseItem.stock">
              {{ purchaseItem.stock.quantity }}
            </span>
          </td>

          <td>
            <span v-if="purchaseItem.user">
              {{ purchaseItem.user.name }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'purchases-details',
                  params: { reference: purchaseItem.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      item_reference: "",
      supplier_reference: "",
      itemCode: "",
      selectedItem: {}, 
      selectedSupplier: {},
      filter: {},
    };
  },
  computed: {
    ...mapGetters("purchase", {
      all: "getAllItems",
    }),

    ...mapGetters("item", {
      items: "getAll",
    }),

    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
  },
  async mounted() {
    await this.$store.dispatch("purchase/fetchAllItems");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("supplier/getAll");
  },
  methods: {
    async search(data) {
      return await this.$store.dispatch("purchase/filterItems", data);
    },
    async refresh() {
      return await this.$store.dispatch("purchase/fetchAllItems");
    },

    async onItemChangeSelected() {
      // await (this.saleItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));

      await (this.filter.item_reference = this.selectedItem.reference);

      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await (this.item_reference = "");
      }
    },

    async onSupplierChangeSelected() {
      await (this.selectedSupplier = this.suppliers.find(
        (p) => p.reference == this.supplier_reference
      ));

      await (this.filter.supplier_reference = this.selectedSupplier.reference);

      // await console.log(this.selectedItem);
      if (this.selectedSupplier != null) {
        await (this.supplier_reference = "");
      }
    },

    async onItemCodeChangeSelected() {
      // await (this.saleItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.code == this.itemCode
      ));

      await (this.filter.item_reference = this.selectedItem.reference);

      await console.log(this.itemCode);
      await console.log(this.items);
      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await (this.itemCode = "");
      }
    },
    async empty() {
      this.item_reference = "";
      this.supplier_reference = "";
      this.itemCode = "";
      this.selectedItem = {};
      this.selectedSupplier = {};
      this.filter = {};
    },
  },
  filters: {
    date: (value) => {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
